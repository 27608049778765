<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .msgLabel {
    height: 40px;
    line-height: 40px;
  }

  .msgName {
    width: 16%;
    min-width: 16%;
  }

  /deep/.el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  /deep/.el-icon-time {
    display: none;
  }

  /deep/.el-input__inner {
    height: 30px;
    padding-left: 10px;
  }

  /deep/.el-input__icon {
    line-height: inherit;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import ExamHeader from "./exam-header";
  import {
    editExamRecord,
    getExamDetails,
    editFiled
  } from "@/api/admin/exam/examRecord.js"
  /**
   * 考试报名--模板

   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ExamHeader


    },
    data() {
      return {
        title: "考试报名信息管理/ ",
        title2: "",
        ksmc: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "考试时间",
            active: true
          }
        ],
        sid: "",
        ksbmbh: "",
        checked: true,
        examForm: {
          bmsjzt: "",
          bmkssj: "",
          bmjssj: "",
          cssjzt: "",
          cskssj: "",
          csjssj: "",
          zpshsjzt: "",
          zpshkssj: "",
          zpshjssj: "",
          jfsjzt: "",
          jfkssj: "",
          jfjssj: "",
          zkzdysjzt: "",
          zkzdykssj: "",
          zkzdyjssj: "",
          kssjzt: "",
          kskssj: "",
          ksjssj: "",
          cjgbsjzt: "",
          cjgbkssj: "",
          cjgbjssj: "",
          cjfhsjzt: "",
          cjfhkssj: "",
          cjfhjssj: "",
          zgfcsjzt: "",
          zgfckssj: "",
          zgfcjssj: "",
          mssjzt: "",
          mskssj: "",
          msjssj: "",
          mjfqrsjzt: "",
          mjfqrkssj: "",
          mjfqrjssj: "",
          qrptdysjzt: "",
          qrptdykssj: "",
          qrptdyjssj: "",
          dzfpsjzt: "",
          dzfpkssj: "",
          dzfpjssj: "",
          zwksjzt: "",
          zwsbkssj: "",
          zwsbjssj: "",
          jmshsjzt:"",
          jmshkssj:"",
          jmshjssj:""
        },

      };
    },
    methods: {
      editExamForm() {
        let formDate = this.examForm
        formDate.isTimePage=1
        editExamRecord({
          ...formDate,
          sid: this.sid,
        }).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.$refs.header.getUrl('/admin/examBaseSet')

      },
      getDetail(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          if (res.status) {
            this.examForm = res.data
            this.ksmc = res.data.ksmc
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj, val) {
        // console.log(obj, val)
        let str = obj.split('sjzt')[0]
        let f = {}
        f[obj] = val
        if (str) {
          if (val == false) {
            // 关闭状态清空对应时间
            this.examForm[str + 'kssj'] = ""
            this.examForm[str + 'jssj'] = ""
          }
        }
        editFiled(this.sid, obj, f)

      },
      // 对应时间不为空改变状态
      updateQyzt(obj) {
        let str
        if (obj.includes('kssj')) {
          str = obj.split('kssj')[0]

        } else if (obj.includes('jssj')) {
          str = obj.split('jssj')[0]
        }
        if (this.examForm[obj]) {
          let newobj = str + 'sjzt'
          this.examForm[newobj] = true
        }
      }
    },
    mounted() {
      if (this.ksbmbh) {
        this.getDetail(this.ksbmbh);
      }
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : "";

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :items="items" />
    <ExamHeader :ksbmbh="ksbmbh" ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <form action="" method="" ref="examForm">
              <div class="outerCotent mt-3 mb-3">
                <input type="hidden" class=" msgValInput w-30" value="" name="sid">
                <div class="msgContent flexList fs-xs">
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgName col-lg-2 text-left">选项</div>
                    <div class="msgName col-lg-2 text-left">是否启用</div>
                    <div class="msgName col-lg-3 text-left">开始时间</div>
                    <div class="msgName col-lg-3 text-left">结束时间</div>
                    <div class="msgName col-lg-2 text-left">说明</div>

                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">报名时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox switch v-model="examForm.bmsjzt" class="mt-3 switch-check"
                        @change="changeQyzt('bmsjzt',examForm.bmsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.bmkssj" class="h30" type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss" @change="updateQyzt('bmkssj')" placeholder="选择日期时间"
                        default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.bmjssj" value-format="yyyy-MM-dd HH:mm:ss" class="h30"
                        type="datetime" @change="updateQyzt('bmjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">初审时间
                    </div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.cssjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('cssjzt',examForm.cssjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.cskssj" value-format="yyyy-MM-dd HH:mm:ss" class="h30"
                        type="datetime" @change="updateQyzt('cskssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.csjssj" value-format="yyyy-MM-dd HH:mm:ss" class="h30"
                        type="datetime" @change="updateQyzt('csjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">照片审核时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.zpshsjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('zpshsjzt',examForm.zpshsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zpshkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('zpshkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zpshjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('zpshjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">缴费时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.jfsjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('jfsjzt',examForm.jfsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.jfkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('jfkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.jfjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('jfjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">打印准考证时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.zkzdysjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('zkzdysjzt',examForm.zkzdysjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zkzdykssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('zkzdykssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zkzdyjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('zkzdyjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">成绩公布时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.cjgbsjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('cjgbsjzt',examForm.cjgbsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.cjgbkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('cjgbkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.cjgbjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('cjgbjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">成绩复核时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.cjfhsjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('cjfhsjzt',examForm.cjfhsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.cjfhkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('cjfhkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.cjfhjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('cjfhjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">资格复查时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.zgfcsjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('zgfcsjzt',examForm.zgfcsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zgfckssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('zgfckssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zgfcjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('zgfcjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">面试时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.mssjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('mssjzt',examForm.mssjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.mskssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('mskssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.msjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('msjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>

                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">免缴费确认时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.mjfqrsjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('mjfqrsjzt',examForm.mjfqrsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.mjfqrkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('mjfqrkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.mjfqrjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('mjfqrjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center">确认凭条打印时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.qrptdysjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('qrptdysjzt',examForm.qrptdysjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.qrptdykssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('qrptdykssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.qrptdyjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('qrptdyjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center"> 电子发票申请时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.dzfpsjzt" switch class="mt-3 switch-check"
                        @change="changeQyzt('dzfpsjzt',examForm.dzfpsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.dzfpkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('dzfpkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.dzfpjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" @change="updateQyzt('dzfpjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center"> 职位库上报时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.zwksjzt" switch class="mt-3 switch-check"
                                       @change="changeQyzt('zwksjzt',examForm.zwksjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zwsbkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                                      type="datetime" @change="updateQyzt('zwsbkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.zwsbjssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                                      type="datetime" @change="updateQyzt('zwsbjssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgVal  col-lg-2 text-center"> 减免审核时间</div>
                    <div class="msgVal   flexList col-lg-2">
                      <b-form-checkbox v-model="examForm.jmshsjzt" switch class="mt-3 switch-check"
                                       @change="changeQyzt('jmshsjzt',examForm.jmshsjzt)">
                      </b-form-checkbox>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.jmshkssj" class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                                      type="datetime" @change="updateQyzt('jmshkssj')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-3 flexList">
                      <el-date-picker v-model="examForm.jmshjssj " class="h30" value-format="yyyy-MM-dd HH:mm:ss"
                                      type="datetime" @change="updateQyzt('jmshjssj ')" placeholder="选择日期时间" default-time="12:00:00">
                      </el-date-picker>
                    </div>
                    <div class="msgVal  col-lg-2 text-center"></div>
                  </div>

                  <div class="flexList w-100 msgLabel" style="height: 60px;">
                    <div class="msgVal col-lg-2"></div>
                    <div class="msgVal flexList">
                      <button class="btn btn-info h35 mr-2 sub-btn" type="button"
                        @click="editExamForm()">保存信息，进入下一步</button>
                      <!-- <a href="/admin/examBaseSet"></a> -->
                    </div>
                  </div>
                </div>

              </div>
            </form>


          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
